<template>
  <div class="wrapper">
    <div class="left-div">
      <div class="container">
        <ItemTitle
          @triggerEvent="triggerEvent"
          :titleData="titleData"
        ></ItemTitle>
        <div class="scrollCont">
          <div style="height:100%">
            <NewTabs :tabs="tabs" :acTab="acTab" @active-tab="handleChangeTabs"></NewTabs>
            <div class="topo-data" v-show="acTab.key === 'active-1'">
              <TopoList
                v-if="topoList"
                :topo-data="topoList"
                @on-trigger="handleMoreEvent"
                @checked="checkedData"
              ></TopoList>
              <div class="topo-add">
                <el-button type="primary" icon="el-icon-plus" circle @click="addTopoGraph"></el-button>
              </div>
            </div>
            <div v-show="acTab.key === 'active-2'">
              <TopoEchart
                :key="key"
                :fenxiData="fenxiData"
                @filter-diagram="filterStatus"
              ></TopoEchart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="25%">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px">
          <el-form-item label="拓扑图名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ItemTitle from '@/views/topology/components/itemTitle'
import NewTabs from '@/views/topology/components/NewTabs'
import TopoEchart from '@/views/topology/components/TopoEchart'
import TopoList from "@/views/topology/components/TopoList";
import {dataInterface} from "@/apis/data";
import {uniqid} from '@/utils/tools.js'
import eventBus from "@/plugins/eventBus";

export default {
  components: {ItemTitle, NewTabs, TopoEchart, TopoList},
  data() {
    return {
      //左侧
      titleData: {
        name: '拓扑任务图',
        icon: 'icontouziguanli',
        action: [
          {
            icon: 'iconsaidi-daohang-shezhi',
            triggerEvent: 'setting',
            name: '设置',
            color: '#fff',
            accessField: 'block_config',
          },
          {
            icon: 'iconshanchu2',
            triggerEvent: 'close',
            name: '关闭',
            color: '#fff',
          },
        ],
      },
      acTab: {key: 'active-1', name: '分析'},
      tabs: [
        {key: 'active-1', name: '列表'},
        {key: 'active-2', name: '分析'},
      ],
      fenxiData: [
        {
          title: '任务状态',
          yAxisData: ['已完成', '未完成', '总任务'],
          seriesData: [
            {name: '已完成', value: '0'},
            {name: '未完成', value: '0'},
            {name: '总任务', value: '0'},
          ],
          colorList: ['#40B67C', '#EF9818', '#54A1FF'],
        },
        {
          title: '预警情况',
          yAxisData: ['严重', '中等', '一般'],
          seriesData: [
            {name: '严重', value: '0'},
            {name: '中等', value: '0'},
            {name: '一般', value: '0'},
          ],
          colorList: ['#FF6747', '#FFB400', '#54A1FF'],
        },
      ],
      ruleForm:{
        name:'',
      },
      rules: {
        name: [{required: true, message: '请输入拓扑图名称', trigger: 'blur'},],
      },
      dialogTitle: '新建拓扑图',
      dialogVisible: false,
      topoGraphId: 0,
      activeGraphId: 0,
      topoList: null,
      key: uniqid()
    }
  },
  watch: {},
  computed: {},
  methods: {
    handleChangeTabs(item) {
      this.acTab = item
    },
    triggerEvent() {
      console.log(11);
    },
    filterStatus(query) {
      eventBus.$emit('filter-graph',query)
    },
    initTopoList(){
      const data = {
        __method_name__: 'dataList',
        object_uuid:'object62a83e285860b',
        view_uuid:'view62a83ead1f4bb',
        transcode: 0,
        size: 500
      }
      dataInterface(data).then(res=>{
        if(res.data.code === 200){
          this.topoList = res.data.data.data
        }
      })
    },
    addTopoGraph(){
      this.topoGraphId = 0
      this.dialogVisible = true
    },
    submitForm(){
      this.$refs.ruleForm.validate((valid)=>{
        if(valid){
          const data = {
            __method_name__: 'createData',
            object_uuid:'object62a83e285860b',
            transcode: 0,
            ...this.ruleForm
          }
          if(this.topoGraphId){
            data.__method_name__ = 'updateData'
            data.data_id = this.topoGraphId
          }
          dataInterface(data).then(res=>{
            if(res.data.code === 200){
              this.initTopoList()
              this.dialogVisible = false
            }
          })
        }
      })
    },
    handleMoreEvent(eventName,flag,row){
      this[eventName](flag,row)
    },
    add(flag,row){
      this.dialogTitle = '新建拓扑图'
      this.ruleForm.name = ''
      this.actionFlag = flag
      if(flag === 'edit' && row){
        this.topoGraphId = row.id
        const {name} = row
        this.ruleForm = {name}
        this.dialogTitle = '重命名拓扑图'
      } else if(row){
        this.topoGraphId = row.id
      }
      this.dialogVisible = true
    },
    del(row){
      this.$confirm('此操作删除此拓扑图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          __method_name__: 'deleteData',
          object_uuid: 'object62a83e285860b',
          data_id: row.id,
          transcode: 0
        }
        dataInterface(params).then((res) => {
          if(res.data.code === 200){
            this.initTopoList()
          }
        })
      })
    },
    checkedData(id){
      const param = {
        __method_name__: 'dataInfo',
        object_uuid: 'object62a83e285860b',
        data_id: id,
        transcode: 0
      }
      dataInterface(param).then(res=>{
        if(res.data.code === 200){
          const {json} = res.data.data
          let total=0, uncomplate=0, complete=0
          if(json){
            for (let cell of json.cells){
              if(['task'].includes(cell.shape)){
                total++
                const status = cell?.data?.status || 1
                if(status === 2){
                  complete++
                } else {
                  uncomplate++
                }
              }
            }
          }
          this.fenxiData[0].seriesData[0].value = complete
          this.fenxiData[0].seriesData[1].value = uncomplate
          this.fenxiData[0].seriesData[2].value = total
          this.key = uniqid()
        }
      })
    },
  },
  created() {
    this.initTopoList()
  },
  mounted() {
  },
}
</script>
<style lang="less" scoped>
.wrapper{
  width: 100%;
  height: 100%
}


.left-div {
  height: 100%;

  .container{
    background: rgba(255, 255, 255, 0.8) !important;
    height: 100%
  }

  .left-left {
    width: 30px;
    height: 100%;
    border: 1px solid #000000;
  }

  .scrollCont{
    overflow-y: auto;
    height: calc(100% - 45px);

    .topo-data{
      height: calc(100% - 35px);
      position: relative;

      .topo-add{
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }
}
</style>
